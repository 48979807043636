export const customBreadcrumbStyles = {
    control: (base) => ({
        ...base,
        minHeight: '30px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        width: '100%',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 6px',
        whiteSpace: 'normal',
        height: 'auto',
    }),
    singleValue: (base) => ({
        ...base,
        maxWidth: '240px',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }),
    input: (base) => ({
        ...base,
        margin: '0px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    menu: (base) => ({
        ...base,
        width: '250px',
        zIndex: 1001,
    }),
    option: (base) => ({
        ...base,
        wordBreak: 'break-word'
    }),
};
