import React, { useState, useMemo, useEffect } from 'react';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';
import 'rc-slider/assets/index.css';
import './_style.scss';

const LIMIT_LEVEL = {
    Alarm: 'Alarm',
    Alert: 'Alert'
};
const STEP = 0.01;

const MultiPointSliderComponent = ({ uniqId, min, max, value, stepCount = 6, showMinMax = false, showPointer = false }) => {
    const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);

    useEffect(() => {
        const container = document.getElementById(uniqId);
        if (!container) { return; }

        const handleElements = container.querySelectorAll('.rc-slider-handle');
        handleElements.forEach(handle => {
            handle.style.border = 'none';
            const ariaValuenow = Math.round(Number(handle.getAttribute('aria-valuenow')));

            const matchingValue = value.find(val => Math.round(val.value) === ariaValuenow);
            if (matchingValue) {
                let borderColor;

                if (matchingValue.type === LIMIT_LEVEL.Alert) {
                    borderColor = colors.orangePeel;
                } else if (matchingValue.type === LIMIT_LEVEL.Alarm) {
                    borderColor = colors.pomegranate;
                }

                handle.style.border = `2px solid ${borderColor}`;
                handle.style.zIndex = '2';
                handle.style.background = 'white';
            }
        });
    }, [value]);

    const handleMouseEnter = (index) => {
        setActiveTooltipIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveTooltipIndex(null);
    };

    const renderPointer = (val, index) => {
        const percentage = (val.value - min) / (max - min);
        return (
            <div
                key={`pointer-${index}-${val.value}`}
                className='pointer-style'
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className='tooltip-style'
                    style={{
                        left: `${percentage * 100}%`,
                        zIndex: activeTooltipIndex === index ? 10 : 1,
                    }}
                >
                    {val.value || 0}
                    <div className='pointer-down-arrow'></div>
                </div>
            </div>
        );
    };


    const step = (max - min) / stepCount;
    const marks = Array.from(
        { length: stepCount + 1 },
        (_, index) => min + index * step
    ).reduce((acc, value) => {
        acc[value] = {
            label: ' ',
        };
        return acc;
    }, {});

    const generateColorRanges = (input) => {
        input.sort((a, b) => Number(a.value) - Number(b.value));

        const tempArr = [min, ...input.map((item) => Number(item.value)), max];
        const newArr = input.map((item) => {
            const idx = tempArr.indexOf(Number(item.value));
            let minVal, maxVal;
            if (item.symbol === '>') {
                minVal = tempArr[idx];
                maxVal = tempArr[idx + 1];
            } else if (item.symbol === '<') {
                minVal = tempArr[idx - 1];
                maxVal = tempArr[idx];
            }
            let backgroundColor;
            if (item.type === LIMIT_LEVEL.Alert) {
                backgroundColor = colors.orangePeel;
            } else if (item.type === LIMIT_LEVEL.Alarm) {
                backgroundColor = colors.pomegranate;
            }
            return { min: minVal, max: maxVal, backgroundColor };
        });

        const uniqueArray = newArr.filter(
            (v, i, a) => a.findIndex((t) => t.min === v.min && t.max === v.max) === i
        );

        const minMaxArray = [];
        const backgroundColorArray = [];

        for (let i = 0; i < tempArr.length - 1; i++) {
            const start = tempArr[i];
            const end = tempArr[i + 1];
            minMaxArray.push(start, end);
            const range = uniqueArray.find(range => range.min === start && range.max === end);
            if (range) {
                backgroundColorArray.push({
                    backgroundColor: range.backgroundColor,
                    zIndex: 2
                });
            } else {
                backgroundColorArray.push({
                    backgroundColor: colors.jungleGreen,
                    zIndex: 2
                });
            }
        }
        const uniqueMinMaxArray = [...new Set(minMaxArray)];

        return [backgroundColorArray, uniqueMinMaxArray];
    };

    const [colorRanges, valueArr1] = useMemo(
        () => generateColorRanges(value),
        [value]
    );

    const pointerStyles = (type) => {
        return {
            opacity: 1,
            zIndex: 3,
            borderColor: type ? type === LIMIT_LEVEL.Alert ? colors.orangePeel : colors.pomegranate : colors.malibu,
        };
    };

    const handleStyle = [min, ...value, max].map((val) => pointerStyles(val?.type));

    return (
        <div className='multi-slider-container'>
            <Slider
                range
                min={Number(min)}
                max={Number(max)}
                value={valueArr1}
                draggableTrack={false}
                handle={null}
                handleStyle={handleStyle}
                marks={marks}
                styles={{ rail: { background: colors.pomegranate } }}
                trackStyle={colorRanges}
                railStyle={{
                    zIndex: 1,
                    background: colors.jungleGreen,
                }}
                dotStyle={{ backgroundColor: colors.gre, borderColor: colors.alto }}
                activeDotStyle={{ backgroundColor: colors.alto, borderColor: colors.alto }}
                step={STEP}
            />
            {showPointer && <div>
                {value.map((val, index) =>
                    renderPointer(val, index)
                )}
            </div>}
            {showMinMax && <div className='min-max'>
                <span>{min}(MIN)</span>
                <span>{max}(MAX)</span>
            </div>}
        </div>
    );
};

MultiPointSliderComponent.propTypes = {
    uniqId: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    showPointer: PropTypes.bool,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            symbol: PropTypes.oneOf(['>', '<']),
            type: PropTypes.oneOf([LIMIT_LEVEL.Alert, LIMIT_LEVEL.Alarm]),
        })
    ),
    stepCount: PropTypes.number,
    showMinMax: PropTypes.bool,
};

export default MultiPointSliderComponent;
