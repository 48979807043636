import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'rc-table';

import { formatColumns, getDataChunkForPage, getTypeClass, sortData } from './helpers';
import { GeneralPagination } from 'sharedComponents/GeneralPagination';
import { assetTypes } from 'helpers/constants';

import './style.scss';


const GeneralTableComponent = ({
    className = '',
    scroll,
    columns,
    data,
    emptyText = '',
    tableLayout = 'auto',
    paginationProps,
    tableKey,
    expandable,
    initialSortingProps = { column: null, direction: null },
    type,
    requiredPaginateHandler = false,
    setSelectedEventItemPage,
    selectedEventItemPage,
    selectedEventId,
    isEventFiltered
}) => {

    const [sortingColumn, setSortingColumn] = useState(initialSortingProps.column);
    const [sortingDirection, setSortingDirection] = useState(initialSortingProps.direction);
    const [formattedColumns, setFormattedColumns] = useState(null);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedPage, setSelectedPage] = useState(1);

    useEffect(() => {
        Array.isArray(data) && data.length ? setSelectedPage(1) : setSelectedPage(0);
    }, [data]);

    const handlePageSelection = (page) => {
        setSelectedPage(page);
    };

    const handleRowClick = (_record, index) => {
        setSelectedRow(index);
        if (requiredPaginateHandler) {
            setSelectedEventItemPage(selectedPage);
        }
    };

    useEffect(() => {
        if (requiredPaginateHandler && selectedEventItemPage) {
            if (!isEventFiltered) {
                if (selectedPage !== selectedEventItemPage) {
                    setSelectedPage(selectedEventItemPage);
                }
            } else {
                setSelectedPage(1);
            }
        }
    }, [selectedEventItemPage, isEventFiltered]);

    useEffect(() => {
        setFormattedColumns(formatColumns(columns, sortingColumn, sortingDirection, setSortingColumn, setSortingDirection));
    }, [columns, sortingColumn, sortingDirection]);


    const formattedData = useMemo(() => {
        const sortedData = sortData(data, sortingColumn, sortingDirection);

        const dataChunk = paginationProps ? getDataChunkForPage(sortedData, selectedPage, paginationProps.pageSize) : [...sortedData];

        return dataChunk;
    }, [data, sortingColumn, sortingDirection, selectedPage, paginationProps]);

    useEffect(() => {
        if (requiredPaginateHandler && selectedEventId && selectedEventItemPage) {
            const selectedRow = document.querySelectorAll('.rc-table-tbody .rc-table-row.selected')[0];
            selectedRow && selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedEventId, formattedData]);

    return <>
        <Table
            scroll={scroll}
            columns={formattedColumns}
            data={formattedData}
            className={`general-table ${className} ${getTypeClass(type)}`}
            emptyText={!data || !data.length ? emptyText : null}
            tableLayout={tableLayout}
            onRow={(record, index) => ({
                onClick: handleRowClick.bind(null, record, index)
            })}
            rowClassName={(record, index) => {
                if (requiredPaginateHandler) {
                    return record.eventId === selectedEventId ? 'selected' : '';
                } else {
                    return selectedRow === index ? 'selected' : '';
                }
            }
            }
            indentSize={30}
            expandable={expandable}
            rowKey={tableKey}
            sticky={false}
            useFixedHeader={false}
        />

        {paginationProps && data && <GeneralPagination
            className='general-table-pagination'
            currentPage={selectedPage}
            total={data?.length}
            pageSize={paginationProps.pageSize ?? 10}
            onChange={handlePageSelection}
            hideOnSinglePage={paginationProps.hideOnSinglePage}
        />}
    </>;
};

GeneralTableComponent.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.any,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    emptyText: PropTypes.string,
    initialSortingProps: PropTypes.shape({
        column: PropTypes.any,
        direction: PropTypes.any
    }),
    paginationProps: PropTypes.shape({
        pageSize: PropTypes.number,
        hideOnSinglePage: PropTypes.bool
    }),
    tableKey: PropTypes.string,
    scroll: PropTypes.any,
    tableLayout: PropTypes.string,
    type: PropTypes.oneOf([assetTypes.PRIMARY, null, undefined]),
    requiredPaginateHandler: PropTypes.bool,
    setSelectedEventItemPage: PropTypes.func,
    selectedEventItemPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isEventFiltered: PropTypes.bool
};

export default GeneralTableComponent;
