
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';


const TrendTooltipMinMaxRow = ({ point, uom }) => {
    const { t: translate } = useTranslation();
    const { low: min, high: max } = point.point || {};
    const isRange = point?.series?.tooltipOptions?.isRange;
    const minMaxMessage = `${translate('ABB.Powertrain.Frontend.opTrendsLblMin')}/${translate('ABB.Powertrain.Frontend.opTrendsLblMax')}: ${min} / ${max} ${uom}}`;
    return <>
        <span className='symbol' style={{ backgroundColor: point.color, opacity: '0.3' }} />
        <span>{isRange === true ? `  ${translate('ABB.Powertrain.Frontend.rangeLabel')} (${translate('ABB.Powertrain.Frontend.rangePercentile')}): ${min} / ${max} ${uom}` : minMaxMessage}</span>
    </>;
};

TrendTooltipMinMaxRow.propTypes = {
    point: PropTypes.shape({
        color: PropTypes.string,
        point: PropTypes.object
    }),

    uom: PropTypes.string
};


export default TrendTooltipMinMaxRow;
