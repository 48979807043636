import PropTypes from 'prop-types';
import React from 'react';
import { toLocaleDateTimeString, getUTCToLocalTimeZone } from 'helpers/dateHelper';
import TrendTooltipPointValueRow from './TrendTooltipPointValueRow';
import TrendTooltipMinMaxRow from './TrendTooltipMinMaxRow';
import { useTranslation } from 'react-i18next';

import './styles.scss';


const TrendTooltip = (props) => {
    const { t: translate } = useTranslation();
    const { points, timeStamp, isUTC, dateConfig } = props;
    const isPersonalLimit = points.map(point => point?.series?.tooltipOptions?.isUserLimit).includes(true);
    return (
        <div className='custom-trend-tooltip'>
            <div className='t-header'>
                {isUTC ? `${getUTCToLocalTimeZone(timeStamp, false, true)} (${translate('ABB.Powertrain.Frontend.UTC')})` : toLocaleDateTimeString(timeStamp, dateConfig)}
            </div>
            {points?.map((point, kpiIndex) => {
                const isMinMax = point?.point?.low !== undefined;
                const uom = point?.series?.tooltipOptions?.valueSuffix?.trim();
                const isPersonalLimits = point?.series?.tooltipOptions?.isUserLimit;
                return (
                    <div className='t-item' key={kpiIndex}>
                        {
                            isMinMax ? <TrendTooltipMinMaxRow point={point} uom={uom} /> : !isPersonalLimits
                                ? <TrendTooltipPointValueRow point={point} uom={uom} /> : null
                        }
                    </div>
                );
            })}
            {isPersonalLimit ? <span className='t-personal-limit'>{translate('ABB.Powertrain.Frontend.opTrendsLblPersonalLimit')}</span> : null}
            {isPersonalLimit ? points?.map((point, kpiIndex) => {
                const uom = point?.series?.tooltipOptions?.valueSuffix?.trim();
                const isPersonalLimits = point?.series?.tooltipOptions?.isUserLimit;
                return (
                    <div className='t-item' key={kpiIndex}>
                        {
                            isPersonalLimits ? <TrendTooltipPointValueRow point={point} uom={uom} />
                                : null
                        }
                    </div>
                );
            }) : null}
        </div>
    );
};

TrendTooltip.propTypes = {
    dateConfig: PropTypes.object,
    points: PropTypes.array,
    timeStamp: PropTypes.number
};

export default TrendTooltip;
