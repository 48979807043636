import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { detectTimeFormat } from '../../PowertrainTools/SensorFirmwareManagement/helpers';

const TimeInput = ({ label, defaultTime, defaultPeriod, setTime, setPeriod, timeError, externalId, validationError, isForStartTime }) => {
    const { t: translate } = useTranslation();
    const is12HoursFormat = isForStartTime && detectTimeFormat();

    const handleTimer = (value) => {
        if (/^[0-9:]*$/.test(value)) {
            if (!value.includes(':')) {
                if (value.length === 2) {
                    value += ':';
                } else if (value.length > 2) {
                    value = value.slice(0, 2) + ':' + value.slice(2);
                }
            } else if (value.includes(':')) {
                const getHoursMinutes = value.split(':');
                if (getHoursMinutes[0].length > 2) {
                    value = value.slice(0, 2) + ':' + value.slice(2, 3);
                } else if (getHoursMinutes[1].length > 2) {
                    return;
                }
            }
            setTime(value);
        }
    };

    const handleKeyDown = (event) => {
        const value = event.target.value;
        if (event.key === ':') {
            event.preventDefault();
        } else if (event.key === 'Backspace' && value[value.length - 1] === ':') {
            setTime(value.slice(0, 2));
            event.preventDefault();
        }
    };

    const handleErrors = () => {
        if (timeError) {
            return translate('ABB.Powertrain.Frontend.errorText');
        }
        if (!isForStartTime && defaultTime.slice(0, 2) > 23) {
            return translate('ABB.Powertrain.Frontend.durationLimitErrorText');
        }
        if (validationError) {
            return translate('ABB.Powertrain.Frontend.invalidTimeText');
        }
    };

    return (
        <div className='time-input-container'>
            <label className='label'>{label}</label>
            <div className='flex'>
                <input
                    type='text'
                    value={defaultTime || ''}
                    maxLength={5}
                    onChange={(e) => handleTimer(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className={`input-time ${timeError || validationError ? 'error' : ''} ${!is12HoursFormat ? 'hour24-format' : ''}`}
                    placeholder='HH:MM'
                    disabled={!externalId}
                />
                {(timeError || validationError) &&
                    <div className={`error-icon ${!is12HoursFormat ? 'hour24-format-icon' : ''}`}>
                        <IconCondition condition={3} ></IconCondition>
                    </div>
                }
                {is12HoursFormat &&
                    <select
                        value={defaultPeriod || ''}
                        onChange={(e) => setPeriod(e.target.value)}
                        className='time-select'
                        disabled={!externalId}
                    >
                        <option value='AM'>AM</option>
                        <option value='PM'>PM</option>
                    </select>
                }
            </div>
            {(timeError || validationError) &&
                <div className='error-message'>
                    {handleErrors()}
                </div>
            }
        </div>
    );
};

TimeInput.propTypes = {
    text: PropTypes.string
};

export default TimeInput;
