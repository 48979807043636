import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import './style.scss';

const RouterTabsComponent = ({ tabs, children, variant = 'default', onChange, defaultTab, getBasePath }) => {
    const history = useHistory();
    const location = useLocation();

    /************************************************************************************
     * Find the active tab by checking if the current pathname starts with the tab's route
     * Recalculate the active tab key whenever the location changes
    *************************************************************************************/
    const activeTabKey = React.useMemo(
        () => tabs.findIndex((tab) => location.pathname.startsWith(`${getBasePath}${tab.route}`)),
        [tabs, location.pathname]
    );

    // Function to handle tab click and navigate to the respective route
    const handleTabClick = (route) => {
        const updatedRoute = `${getBasePath}${route}`;
        history.push(updatedRoute);
        if (onChange) {
            onChange(updatedRoute);
        }
    };

    useEffect(() => {
        if (defaultTab && !location.pathname.startsWith(defaultTab)) {
            const updatedDefaultTab = `${getBasePath}${defaultTab}`;
            history.push(updatedDefaultTab);
        }
    }, [defaultTab, history]);

    return (
        <div className={`tab-component ${variant}`}>
            <div className='tab-header'>
                {tabs.map((tab, index) =>
                    <button
                        key={tab.route}
                        className={`tab-item ${activeTabKey === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab.route)}
                    >
                        {tab.title}
                    </button>
                )}
            </div>
            <div className='tab-content'>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child)
                )}
            </div>
        </div>
    );
};

RouterTabsComponent.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            route: PropTypes.string.isRequired,
        })
    ).isRequired,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['default', 'toggle']),
    onChange: PropTypes.func,
    defaultTab: PropTypes.string,
};

RouterTabsComponent.defaultProps = {
    onChange: null,
    defaultTab: '',
};

export default RouterTabsComponent;
