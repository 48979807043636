import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Checkbox = ({ onToggle, id, checked, disabled, label, width = '24px', height = '24px', viewBox = '0 0 48 48' }) => {

	const indeterminate = checked === null || checked === undefined;

	const handleToggle = () => {
		if (typeof onToggle === 'function' && !disabled) {
			onToggle();
		}
	};

	return <div
		role='checkbox'
		id={id}
		className={disabled ? 'custom-checkbox-disabled' : 'custom-checkbox'}
		onClick={handleToggle}
	>
		{label ? <div className='checkbox-label'>{label}</div> : null}
		<svg className='checkbox' width={width} height={height} viewBox={viewBox} xmlns='http://www.w3.org/2000/svg'>
			<g fill='none'>
				<rect className={checked || indeterminate ? 'fill-checked' : 'fill-empty'} x='6.4688' y='6.2812' width='35.344' height='35.344' strokeLinecap='square' strokeWidth='5' />
				{indeterminate ?
					<rect className={indeterminate ? 'indeterminate' : 'fill-white'} x='12' y='21' width='24' height='6' />
					: checked &&
					<path d='m14.385 25.063 7.0269 6.4303 12.728-15.844' strokeWidth='4.5' />
				}
			</g>
		</svg>
	</div>;
};

Checkbox.propTypes = {
	id: PropTypes.string,
	checked: PropTypes.bool,
	onToggle: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.string,
};

export default Checkbox;
