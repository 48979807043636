
import PropTypes from 'prop-types';
import React from 'react';

const TrendTooltipPointValueRow = ({ point, uom }) => {
    const pointValue = point.y;
    const symbolName = point?.point?.series?.symbol;

    const renderSymbol = () => {
        let symbol = '■';

        if (symbolName) {
            switch (symbolName) {
                case 'circle':
                    symbol = '●';
                    break;
                case 'diamond':
                    symbol = '♦';
                    break;
                case 'square':
                    symbol = '■';
                    break;
                case 'triangle':
                    symbol = '▲';
                    break;
                case 'triangle-down':
                    symbol = '▼';
                    break;
                default:
                    symbol = '■';
            }
        }
        return symbol;
    };

    return (
        <>
            {
                point.series.userOptions.component &&
                <div><b>{point.series.userOptions.component}</b></div>
            }
            {/* <span className='symbol' style={{ backgroundColor: point.color, opacity: '1' }} /> */}
            <span className='symbol' style={{ color: point.color, opacity: '1' }} >{renderSymbol()}</span>
            <span>{` ${point.series.name}: ${pointValue} ${uom}`}</span>
        </>
    );
};

TrendTooltipPointValueRow.propTypes = {
    point: PropTypes.shape({
        color: PropTypes.string,
        series: PropTypes.shape({
            name: PropTypes.string,
            userOptions: PropTypes.shape({
                allSignal: PropTypes.bool,
                component: PropTypes.string
            })
        }),
        y: PropTypes.number
    }),
    uom: PropTypes.string
};

export default TrendTooltipPointValueRow;
